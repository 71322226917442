@font-face {
    font-family: 'FbSpacerBold';
    src: url(assets/fonts/FbSpacer-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'FbSpacerRegular';
    src: url(assets/fonts/FbSpacer-Regular.otf) format('opentype');
}

@font-face {
    src: url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
}

@font-face {
    src: url("https://fonts.googleapis.com/icon?family=Material+Icons");
}